<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      table-height="97%"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      :width="1200"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm" @change-select="handleChangeSelect"></lz-form>
      <contact :list="contactList"></contact>
      <order-list ref="orderList"></order-list>
    </lz-dialog>
    <user ref="user"></user>
  </div>
</template>
<script>
import cfg from './config';
import user from './user';
import orderList from './orderList';
import contact from './contact';

export default {
  components: {
    user,
    contact,
    orderList,
  },
  data() {
    return {
      search: JSON.parse(JSON.stringify(cfg.search)),
      config: {
        url: '',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (el.btn_user) {
              el.htmlButton.push({
                name: '查看账号',
                key: 'user',
                type: 'primary',
              });
            }
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        { name: '销售渠道', prop: 'sale_type_name' },
        { name: '企业类型', prop: 'business' },
        { name: '行业', prop: 'industry' },
        { name: '区域',
          func: (ops) => `${ops.contact_province_name} ${ops.contact_city_name} ${ops.contact_area_name}`,
        },
        // {
        //   name: '是否显示',
        //   func: (ops) => (ops.status ? '否' : '是'),
        // },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: cfg.form,
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
      contactList: [],
    };
  },
  created() {
    const name = this.$route.name;
    if (name === 'company-list') {
      this.config.url = '/company/list?is_deal=1';
    }
    if (name === 'company-not-deal') {
      this.config.url = '/company/list?is_deal=0';
    }
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/company/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'user':
          this.$refs.user.init(element.id);
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.contact = this.contactList;
          params.contact.forEach((el) => {
            delete el.htmlButton; // eslint-disable-line
          });
          const response = await this.$baseHttp.post('/company/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        const params = {};
        if (response) {
          params.url = `/company/detail?id=${response.id}`;
        } else {
          params.data = {};
        }
        const data = await this.$refs.lzForm.setValue(params);
        this.contactList = data ? data.contact : [];
        this.$refs.orderList.init(response);
        const saletype = this.form.find((r) => r.fieldName === 'sale_type');
        this.handleChangeSelect(saletype, this.form);
      });
    },
    handleChangeSelect(element, list) {
      if (element.fieldName === 'sale_type') {
        const agentId = list.find((r) => r.fieldName === 'agent_id');
        this.$set(agentId, 'required', element.value === '2');
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
