export default {
  search: [
    { name: '客户名称', type: 'text', fieldName: 'keyword', value: '' },
    { name: '联系人名字/电话', type: 'text', fieldName: 'contact_keyword', value: '' },
    {
      name: '企业类型',
      type: 'select',
      fieldName: 'business_id',
      value: '',
      remote: true,
      props: {
        url: '/dict/business/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '行业',
      type: 'select',
      fieldName: 'industry_id',
      value: '',
      remote: true,
      props: {
        url: '/dict/industry/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '规模',
      type: 'select',
      fieldName: 'scale_id',
      value: '',
      remote: true,
      props: {
        url: '/dict/scale/list',
        value: 'id',
        label: 'name',
      },
    },
    // { name: '行业', type: 'select', fieldName: 'industry_id', value: '', constantDict: 'industry' },
    // { name: '规模', type: 'select', fieldName: 'scale_id', value: '', constantDict: 'scale' },
    {
      name: '区域',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 2 },
      },
    },
    {
      fieldName: 'sale_type',
      type: 'select',
      name: '销售渠道',
      value: '',
      options: [],
      constantDict: 'saleType',
    },
    // {
    //   name: '用途',
    //   type: 'select',
    //   fieldName: 'purpose_id',
    //   value: '',
    //   remote: true,
    //   props: {
    //     url: '/purpose/list',
    //     value: 'id',
    //     label: 'name',
    //   },
    // },
    // {
    //   name: '价格',
    //   type: 'select',
    //   fieldName: 'price_title_id',
    //   value: '',
    //   remote: true,
    //   props: {
    //     url: '/price-title/list',
    //     value: 'id',
    //     label: 'name',
    //   },
    // },
    // {
    //   name: '场景',
    //   type: 'select',
    //   fieldName: 'scene_id',
    //   value: '',
    //   remote: true,
    //   props: {
    //     url: '/scene/list',
    //     value: 'id',
    //     label: 'name',
    //   },
    // },
    { name: 'id', type: 'text', fieldName: 'id', value: '', hide: true },
  ],
  form: [
    { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
    {
      fieldName: 'status',
      type: 'select',
      name: '状态',
      required: true,
      value: '',
      options: [
        { label: '启用', value: 0 },
        { label: '禁用', value: 1 },
      ],
    },
    {
      name: '所在城市',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id||contact_area_id',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '55%',
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 3 },
        checkStrictly: true,
      },
    },
    { fieldName: 'contact_address', type: 'text', name: '详细地址', value: '', required: false, width: '45%' },
    {
      name: '销售渠道',
      type: 'select',
      fieldName: 'sale_type',
      value: [],
      options: [],
      width: '50%',
      constantDict: 'saleType',
    },
    {
      name: '上级分销客户',
      type: 'select',
      fieldName: 'agent_id',
      value: [],
      options: [],
      remote: true,
      required: false,
      width: '50%',
      props: {
        url: '/agent/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '产品用途',
      type: 'select',
      fieldName: 'purpose_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/purpose/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '礼一价格',
      type: 'select',
      fieldName: 'price_title_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/price-title/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '应用场景',
      type: 'select',
      fieldName: 'scene_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/scene/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '企业类型',
      type: 'select',
      fieldName: 'business_id',
      value: '',
      required: true,
      remote: true,
      props: {
        url: '/dict/business/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '行业',
      type: 'select',
      fieldName: 'industry_id',
      value: '',
      remote: true,
      required: true,
      props: {
        url: '/dict/industry/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '规模',
      type: 'select',
      fieldName: 'scale_id',
      value: '',
      remote: true,
      required: true,
      props: {
        url: '/dict/scale/list',
        value: 'id',
        label: 'name',
      },
    },
    // {
    //   fieldName: 'business_id',
    //   constantDict: 'business',
    //   type: 'select',
    //   name: '企业类型',
    //   required: true,
    //   value: '',
    //   options: [],
    // },
    // {
    //   fieldName: 'industry_id',
    //   constantDict: 'industry',
    //   type: 'select',
    //   name: '行业',
    //   required: true,
    //   value: '',
    //   options: [],
    // },
    // {
    //   fieldName: 'scale_id',
    //   constantDict: 'scale',
    //   type: 'select',
    //   name: '规模',
    //   required: true,
    //   value: '',
    //   options: [],
    // },
    { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
    {
      fieldName: 'attachment_ids',
      responseName: 'attachment',
      type: 'upload',
      name: '附件',
      value: [],
      multiple: true,
      required: false,
      width: '100%',
    },
    { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
  ],
};
