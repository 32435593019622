<template>
  <div>
    <lz-dialog
      title="用户信息"
      :is-close-btn="false"
      ref="lzDialog">
      <lz-list
        :config="config"
        :table="table"
        :is-router="false"
        :search-btn="searchBtn"
        @search-btn-click="handleCommonBtnCLick"
        @table-btn-click="handleCommonBtnCLick"
        :search="search"
        ref="lzList">
      </lz-list>
    </lz-dialog>
    <lz-dialog
      :width="500"
      :dialogBtns="[
        { key: 'save', name: '保存', type: 'primary' }
      ]"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialogForm">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      search: [
        { name: '客户id', fieldName: 'company_id', value: '' },
      ],
      searchBtn: [],
      table: [
        { name: 'ID', prop: 'id' },
        { name: '账号', prop: 'user_name' },
        { name: '手机', prop: 'phone' },
        { name: '姓名', prop: 'nick_name' },
        {
          name: '是否启用',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'user_name', type: 'text', name: '账号', value: '', required: true },
        { fieldName: 'password', type: 'password', name: '密码', value: '', required: true },
        { fieldName: 'nick_name', type: 'text', name: '姓名', value: '', required: true },
        { fieldName: 'phone', type: 'text', name: '电话', value: '' },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        {
          fieldName: 'role_id',
          type: 'select',
          name: '所属角色',
          required: true,
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/company-auth-group/list',
            value: 'id',
            label: 'name',
          },
        },
        { fieldName: 'company_id', type: 'text', name: 'company_id', value: '', hide: true },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      config: {
        url: '/company-user/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$baseSetButtonText(el, 'edit', '编辑');
            this.$set(el, 'password', '');
          });
          return list;
        },
      },
    };
  },
  mounted() {
    if (this.$route.meta.roleBtn.add_user) {
      this.searchBtn = [{ name: '添加', type: 'danger', key: 'add' }];
    }
  },
  methods: {
    init(id) {
      this.search[0].value = id;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
    async handleCommonBtnCLick(type, element) {
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/company-user/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'add':
          this.handleDialogForm();
          break;
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          console.log(params.password);
          if (params.password === '') delete params.password;
          const response = await this.$baseHttp.post('/company-user/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialogForm.dialogVisible = false;
        }
          break;
        default:
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}用户信息`;
      this.form.filter((r) => ['password'].includes(r.fieldName)).forEach((el) => {
        this.$set(el, 'required', response === null);
      });
      this.$refs.lzDialogForm.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {
            company_id: this.search[0].value,
          },
        });
      });
    },
  },
};
</script>
