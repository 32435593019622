<template>
  <lz-card
    class="mt-20"
    v-if="search[0].value != ''"
    title="订单详细">
    <lz-list
      :config="config"
      @table-btn-click="handleTableBtnClick"
      :table="table"
      :is-router="false"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-card>
</template>
<script>
export default {
  props: {
    companyId: {
      default: '',
    },
  },
  data() {
    return {
      list: [],
      config: {
        url: '/ticket/list',
        setTableFormat: (response) => {
          response.forEach((element) => {
            this.$set(element, 'htmlButton', [{
              name: '详情',
              key: 'view',
              type: 'success',
            }]);
          });
          return response;
        },
      },
      search: [
        { name: '客户id', fieldName: 'company_id', value: '' },
      ],
      table: [
        {
          name: '属性',
          func: (ops) => (ops.type ? '兑换券' : '礼品券'),
        },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene_name' },
        { name: '用途', prop: 'purpose_name' },
        { name: '价位', prop: 'price_title_name' },
        { name: '订单类型', prop: 'type_name' },
        { name: '数量', prop: 'ticket_num' },
        {
          name: '是否显示',
          prop: 'is_show',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_show),
          switchChange: (row) => this.handleSwitchChange('is_show', row),
        },
        { name: '操作', prop: 'htmlButton' },
      ],
    };
  },
  methods: {
    init(response) {
      const company = this.search[0];
      if (!response) {
        company.value = '';
        return;
      }
      company.value = response.id;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
    handleTableBtnClick(type, element) {
      const params = {
        company_id: element.company_id,
        year: element.year,
        scene_id: element.scene_id,
        purpose_id: element.purpose_id,
        price_title_id: element.price_title_id,
      };
      window.open(`/ticket/ticket-list?${this.$jsonToUrl(params)}`);
    },
    handleSwitchChange(type, row) {
      this.$baseHttp.post('/ticket/show', {
        id: row.id,
        is_show: row[type],
      }).then((response) => {
        if (!response) {
          this.$set(row, `is_${type}`, Number(!row[`is_${type}`]));
          return;
        }
        this.$message.success('操作成功');
      });
    },
  },
};
</script>
